let fakeData = false;

export function isFakeDataEnabled() {
    return fakeData;
}

export function enableFakeData() {
    fakeData = true;
}

export function disableFakeData() {
    fakeData = false;
}

export const getLearningSessionService = async () => {
    if(fakeData) {
        return new (await import('./seeds/FakeLearningSessionService')).FakeLearningSessionService();
    } 
    
    return new (await import('./LearningSessionService')).LearningSessionService();
}

export const getBillingService = async () => {
    if(fakeData) {
        return new (await import('./seeds/FakeBillingService')).FakeBillingService();
    }

    return new (await import('./BillingService')).BillingService();
}

export const getProductService = async () => {
    if(fakeData) {
        return new (await import('./seeds/FakeProductService')).FakeProductService();
    }

    return new (await import('./ProductService')).ProductService()
}