import revive_payload_client_YTGlmQWWT5 from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/opt/buildhome/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import posthog_client_nd0wsGWaUN from "/opt/buildhome/repo/node_modules/nuxt-posthog/dist/runtime/plugins/posthog.client.js";
import payload_client_VTarsGAzgb from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PKIXU5KmsI from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/apps/web/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import directives_51p1sMFNNx from "/opt/buildhome/repo/node_modules/nuxt-posthog/dist/runtime/plugins/directives.js";
import primevue_plugin_egKpok8Auk from "/opt/buildhome/repo/apps/web/.nuxt/primevue-plugin.mjs";
import plugin_client_du5xVgaLWQ from "/opt/buildhome/repo/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import dataProviders_bM9LDpun48 from "/opt/buildhome/repo/apps/web/plugins/dataProviders.ts";
import sentry_3AyO8nEfhE from "/opt/buildhome/repo/apps/web/plugins/sentry.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  posthog_client_nd0wsGWaUN,
  payload_client_VTarsGAzgb,
  navigation_repaint_client_PKIXU5KmsI,
  check_outdated_build_client_LIYcCMJD18,
  chunk_reload_client_SeG0EjL5Ec,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  directives_51p1sMFNNx,
  primevue_plugin_egKpok8Auk,
  plugin_client_du5xVgaLWQ,
  dataProviders_bM9LDpun48,
  sentry_3AyO8nEfhE
]