import { defineNuxtPlugin, useRuntimeConfig } from '#app'
import { enableFakeData } from '@t16t/shared/services';

export default defineNuxtPlugin(async (nuxtApp) => {
    const config = useRuntimeConfig();

    if(config.public.useFakeData === 'true') {
        enableFakeData();
    }
})
