import type { Answer, LearningSessionDetails, Question } from '@t16t/sdk'
import { computed, reactive } from 'vue'

export interface QuestionWithContext extends Question {
  selectedAnswer: Answer | null
  isFlagged: boolean
  shouldRevealAnswers: boolean
}

const state = reactive<{
  questions: QuestionWithContext[]
  learningSessionStatus: LearningSessionDetails['status']
  learningSessionUuid: string
  learningSessionType: LearningSessionDetails['type']
  subjectName: string
  topics: string[]
  learningSessionCreatedAt: string,
  currentQuestionIndex: number
}>({ 
  questions: [],
  learningSessionStatus: 'NOT_STARTED', 
  learningSessionUuid: '', 
  learningSessionType: 'STUDY_MODE',
  subjectName: '',
  topics: [],
  learningSessionCreatedAt: '',
  currentQuestionIndex: 0
})

const getters = {
  countOfSelectedAnswers: computed(() => state.questions.filter(question => !!question.selectedAnswer).length),
  haveAnsweredAllQuestions: computed(() => state.questions.filter(question => !!question.selectedAnswer).length === state.questions.length),
  countOfCorrectAnswers: computed(() => state.questions.filter(question => !!question.selectedAnswer).filter(question => question.selectedAnswer?.isCorrect).length),
}
export function useLearningSessionStore() {
  const actions = {
    initialize(learningSession: LearningSessionDetails) {
      this.setQuestions(learningSession.questions);
      this.setLearningSessionUuid(learningSession.entityId);
      this.setLearningSessionType(learningSession.type);
      this.setSubjectName(learningSession.subjects[0]);
      this.setTopics(learningSession.topics);
      this.setLearningSessionCreatedAt(learningSession.createdAt);
    },
    // !TODO fix name of function
    setQuestions(questions: Question[]) {
      state.questions = questions.map((question) => {
        const selectedAnswer = question.answers.find(answer => answer.entityId === question.selectedAnswerEntityId) || null
        const shouldRevealAnswer = () => {
          return !!selectedAnswer
        }
        return {
          ...question,
          selectedAnswer,
          isFlagged: false,
          shouldRevealAnswers: shouldRevealAnswer(),
        } as QuestionWithContext
      },
      )
    },
    toggleFlagOfQuestion(selectedQuestion: QuestionWithContext) {
      state.questions = state.questions.map((question) => {
        if (question.entityId === selectedQuestion.entityId)
          question.isFlagged = !question.isFlagged

        return question
      })
    },
    setSelectedAnswer(ofQuestion: QuestionWithContext, answer: Answer | null) {
      state.questions = state.questions.map((question) => {
        if (question.entityId === ofQuestion.entityId) {
          question.selectedAnswer = answer
          question.selectedAnswerEntityId = answer ? answer.entityId : null
        }

        return question
      })
    },
    /**
     *
     * @param ofQuestion
     * @deprecated Well, it should stay here if we ever need it but I am removing reset from the frontend code
     */
    resetAnswerSelection(ofQuestion: QuestionWithContext) {
      state.questions = state.questions.map((question) => {
        if (question.entityId === ofQuestion.entityId) {
          question.selectedAnswer = null
          question.selectedAnswerEntityId = null
        }

        return question
      })
    },
    revealAnswers(ofQuestion: QuestionWithContext) {
      state.questions = state.questions.map((question) => {
        if (question.entityId === ofQuestion.entityId)
          question.shouldRevealAnswers = true

        return question
      })
    },
    /**
     * @param ofQuestion
     * @deprecated Well, it should stay here if we ever need it but I am removing reset from the frontend code
     */
    hideAnswers(ofQuestion: QuestionWithContext) {
      state.questions = state.questions.map((question) => {
        if (question.entityId === ofQuestion.entityId)
          question.shouldRevealAnswers = false

        return question
      })
    },
    // TODO: fix this type
    setLearningStatusStatus(status: LearningSessionDetails['status']) {
      // if (status === 'NOT_STARTED')
      //   throw new Error('Can\'t set the learning session to NOT_STARTED')
      state.learningSessionStatus = status
    },
    setLearningSessionUuid(uuid: string) {
      // if (status === 'NOT_STARTED')
      //   throw new Error('Can\'t set the learning session to NOT_STARTED')
      state.learningSessionUuid = uuid
    },
    setLearningSessionType(type: LearningSessionDetails['type']) {
      state.learningSessionType = type;
    },
    setSubjectName(name: string) {
      state.subjectName = name;
    },
    setTopics(topics: string[]) {
      state.topics = topics;
    },
    setLearningSessionCreatedAt(date: string) {
      state.learningSessionCreatedAt = date;
    },
    setCurrentQuestionIndex(value: number) {
      state.currentQuestionIndex = value;
    }
  }

  return {
    state,
    actions,
    getters,
  }
}
