import * as Sentry from '@sentry/vue'
import { defineNuxtPlugin, useRuntimeConfig } from '#app'

export default defineNuxtPlugin(async (nuxtApp) => {
  const dsn = useRuntimeConfig().public.sentryDSN as string

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn,
    release: '0.0.9',
    integrations: [
      Sentry.browserTracingIntegration(),
      // Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    // tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: [/^https:\/\/t16t-backend-cloud-run-83d9bad-5eqrs4trhq-ez\.a.run.app\/api\/v1/],
    // Session Replay
    // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
})
