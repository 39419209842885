import { default as callbackWfnGbwEK5AMeta } from "/opt/buildhome/repo/apps/web/pages/auth/callback.vue?macro=true";
import { default as indexvOYtSwSWwOMeta } from "/opt/buildhome/repo/apps/web/pages/auth/index.vue?macro=true";
import { default as get_45started_45guidefwUXW5EfVhMeta } from "/opt/buildhome/repo/apps/web/pages/get-started-guide.vue?macro=true";
import { default as indexLorZLIDR3KMeta } from "/opt/buildhome/repo/apps/web/pages/index.vue?macro=true";
import { default as indexotVGftIyN9Meta } from "/opt/buildhome/repo/apps/web/pages/learning-session/[uuid]/index.vue?macro=true";
import { default as infoIG4f0OQSurMeta } from "/opt/buildhome/repo/apps/web/pages/learning-session/[uuid]/info.vue?macro=true";
import { default as reviewKf3eVuZIGCMeta } from "/opt/buildhome/repo/apps/web/pages/learning-session/[uuid]/review.vue?macro=true";
import { default as createYN6CZUXbj8Meta } from "/opt/buildhome/repo/apps/web/pages/learning-session/create.vue?macro=true";
import { default as historyWliqNSSRZFMeta } from "/opt/buildhome/repo/apps/web/pages/learning-session/history.vue?macro=true";
import { default as indexME7YTPFplyMeta } from "/opt/buildhome/repo/apps/web/pages/products/subjects/index.vue?macro=true";
import { default as walletUmVzb7t4UqMeta } from "/opt/buildhome/repo/apps/web/pages/wallet.vue?macro=true";
export default [
  {
    name: "AuthCallback",
    path: "/auth/callback",
    meta: callbackWfnGbwEK5AMeta || {},
    component: () => import("/opt/buildhome/repo/apps/web/pages/auth/callback.vue").then(m => m.default || m)
  },
  {
    name: "Auth",
    path: "/auth",
    meta: indexvOYtSwSWwOMeta || {},
    component: () => import("/opt/buildhome/repo/apps/web/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: "GetStartedGuide",
    path: "/get-started-guide",
    meta: get_45started_45guidefwUXW5EfVhMeta || {},
    component: () => import("/opt/buildhome/repo/apps/web/pages/get-started-guide.vue").then(m => m.default || m)
  },
  {
    name: "Home",
    path: "/",
    meta: indexLorZLIDR3KMeta || {},
    component: () => import("/opt/buildhome/repo/apps/web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "Session",
    path: "/learning-session/:uuid()",
    meta: indexotVGftIyN9Meta || {},
    component: () => import("/opt/buildhome/repo/apps/web/pages/learning-session/[uuid]/index.vue").then(m => m.default || m)
  },
  {
    name: "SessionInfo",
    path: "/learning-session/:uuid()/info",
    meta: infoIG4f0OQSurMeta || {},
    component: () => import("/opt/buildhome/repo/apps/web/pages/learning-session/[uuid]/info.vue").then(m => m.default || m)
  },
  {
    name: "SessionReview",
    path: "/learning-session/:uuid()/review",
    meta: reviewKf3eVuZIGCMeta || {},
    component: () => import("/opt/buildhome/repo/apps/web/pages/learning-session/[uuid]/review.vue").then(m => m.default || m)
  },
  {
    name: "CreateSession",
    path: "/learning-session/create",
    meta: createYN6CZUXbj8Meta || {},
    component: () => import("/opt/buildhome/repo/apps/web/pages/learning-session/create.vue").then(m => m.default || m)
  },
  {
    name: "History",
    path: "/learning-session/history",
    meta: historyWliqNSSRZFMeta || {},
    component: () => import("/opt/buildhome/repo/apps/web/pages/learning-session/history.vue").then(m => m.default || m)
  },
  {
    name: "Store",
    path: "/products/subjects",
    meta: indexME7YTPFplyMeta || {},
    alias: ["/store"],
    component: () => import("/opt/buildhome/repo/apps/web/pages/products/subjects/index.vue").then(m => m.default || m)
  },
  {
    name: "Wallet",
    path: "/wallet",
    meta: walletUmVzb7t4UqMeta || {},
    component: () => import("/opt/buildhome/repo/apps/web/pages/wallet.vue").then(m => m.default || m)
  }
]